import React, { SVGProps } from 'react';

const WorkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 4.5H14V2.5C14 1.39 13.11 0.5 12 0.5H8C6.89 0.5 6 1.39 6 2.5V4.5H2C0.89 4.5 0.00999999 5.39 0.00999999 6.5L0 17.5C0 18.61 0.89 19.5 2 19.5H18C19.11 19.5 20 18.61 20 17.5V6.5C20 5.39 19.11 4.5 18 4.5ZM12 4.5H8V2.5H12V4.5Z"
      fill="currentColor"
    />
  </svg>
);

export default WorkIcon;

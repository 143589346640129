import { dummyPlans } from '../../constants/dummy.constants';
import { Box, Heading } from 'grommet';
import PlanCard from './PlanCard';

function Subscribtions() {
  return (
    <Box >
      <Heading margin={{ top: '28px', left:"32px" }} level={'3'}>
        Plans
      </Heading>
      <Box wrap width={'100%'} direction="row" justify="evenly">
        {dummyPlans.map((plan) => (
          <PlanCard {...plan} activePlan="Basic" />
        ))}
      </Box>
    </Box>
  );
}

Subscribtions.propTypes = {};

export default Subscribtions;

import Subscribtions from 'components/Subscribtions/Subscribtions';
import UserDashboard from 'components/DashboardLayout/UserDashboard';

function subscribtions() {
  return (
    <UserDashboard >
    
      <Subscribtions />
    </UserDashboard>
  );
}

subscribtions.propTypes = {};

export default subscribtions;

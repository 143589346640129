import { ISubscribtioPlan } from 'components/Subscribtions/PlanCard';
import { IPayoutItem } from 'components/Payouts/PayoutItem';
import { IEvent } from 'components/EventDashboardHeader';

export const dummyPayoutList: IPayoutItem[] = [
  {
    accountName: 'Bongani Khumalo',
    billingAddress: 'Sarit Centre, Westlands, Nairobi, Kenya 20115	',
    routingNumber: 'CHAS - US - 33 - XXX',
    accountNmuber: '00001234546789'
  },
  {
    accountName: 'Bongani Khumalo',
    billingAddress: 'Sarit Centre, Westlands, Nairobi, Kenya 20115',
    swiftCode: 'CHAS - US - 33 - XXX',
    accountNmuber: '00001234546789'
  }
];

export const dummyPlans: ISubscribtioPlan[] = [
  {
    name: 'Basic',
    highlight: {
      main: 'Up to 1000',
      sub: 'tickets per event.'
    },
    iconName: 'home',
    colorScheme: '#18B6B0',
    benefitsSummary: 'Benefits:',
    benefits: ['Free for free events', '6% commision', '0% transaction costs'],
    features: [
      'Ticketing & discovery ',
      'Self service',
      'Localized language and payments',
      'Customer database building',
      'Feedback collection tools'
    ]
  },
  {
    name: 'Pay as you go',
    highlight: {
      main: '1000+',
      sub: 'tickets per event'
    },
    iconName: 'work',
    colorScheme: '#FFCB3C',
    benefitsSummary: 'All Basic benefits plus:',
    benefits: [
      'Free for free events',
      '6% commision',
      '0% transaction costs',
      '3% on additional services'
    ],
    features: [
      'Customer database building',
      'Payments',
      'Fraud detection',
      'Planning tools & templates'
    ]
  },
  {
    name: 'Professional',
    highlight: {
      main: 'Unlimited',
      sub: 'tickets per event'
    },
    iconName: 'lightbulb',
    colorScheme: '#8264EE',
    benefits: ['6.5% commision', '0% transaction costs', '3% on additional services'],
    benefitsSummary: 'All Pay as you go benefits plus:',
    features: [
      'Virtual event tools',
      'Community building tools',
      'Targeted marketing support',
      'Cashless event wallet'
    ]
  }
];

export const dummyBilling = [
  {
    date: Date(),
    plan: 'BASIC',
    amount: 'KES 30'
  },
  {
    date: Date(),
    plan: 'BASIC',
    amount: 'KES 30'
  }
];

export const dummyEvents: IEvent[] = [
  {
    end_date: '2021-10-31T14:30:00+00:00',
    event_type: 'recurring',
    status: 'unpublished',
    start_date: '2021-10-31T12:00:00+00:00',
    venue: {
      address_line1: 'Online',
      address_line2: null
    },
    name: 'TWA Virtual Event',
    address: 'Online',
    image_url: 'https://cdn.tutadoo.com/img/x443hvfk.ecb.jpg'
  },
  {
    end_date: '2021-10-30T20:05:00+00:00',
    event_type: 'recurring',
    status: 'published',
    start_date: '2021-10-30T12:05:00+00:00',
    venue: {
      address_line1: 'Two Grapes Kilimani',
      address_line2: null
    },
    name: 'Nneka Live in Concert',
    address: 'Two Grapes Kilimani',
    image_url: ''
  },

  {
    end_date: '2021-12-12T16:00:00+00:00',
    event_type: 'recurring',
    status: 'unpublished',
    start_date: '2021-12-11T03:00:00+00:00',
    venue: {
      address_line1: 'Kajiado',
      address_line2: null
    },
    name: 'Jamuhuri Ya Mashuuru',
    address: 'Kajiado',
    image_url: 'https://cdn.tutadoo.com/img/qeadtnjt.sf0.png'
  },
  {
    end_date: '2021-12-24T19:00:00+00:00',
    event_type: 'recurring',
    status: 'unpublished',
    start_date: '2021-12-23T15:00:00+00:00',
    venue: {
      address_line1: 'Nairobi',
      address_line2: null
    },
    name: 'Habitat Fest',
    address: 'Nairobi',
    image_url: ''
  }
];

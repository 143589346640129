import React, { SVGProps } from 'react';

const HomeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99778 16.3279V11.3279H11.9978V16.3279C11.9978 16.8779 12.4478 17.3279 12.9978 17.3279H15.9978C16.5478 17.3279 16.9978 16.8779 16.9978 16.3279V9.32785H18.6978C19.1578 9.32785 19.3778 8.75785 19.0278 8.45785L10.6678 0.927852C10.2878 0.587852 9.70778 0.587852 9.32778 0.927852L0.967779 8.45785C0.627779 8.75785 0.837779 9.32785 1.29778 9.32785H2.99778V16.3279C2.99778 16.8779 3.44778 17.3279 3.99778 17.3279H6.99778C7.54778 17.3279 7.99778 16.8779 7.99778 16.3279Z"
      fill="currentColor"
    />
  </svg>
);

export default HomeIcon;

import React from 'react';
import { Box, Heading, Text, Button } from 'grommet';
import { CheckRoundIcon, HomeIcon, WorkIcon, LightBulbIcon } from 'components/icons';
import { IconButton } from 'grommet-controls';

type HighLight = {
  main: string;
  sub: string;
};

export interface ISubscribtioPlan {
  name: string;
  colorScheme: string;
  highlight: HighLight | string;
  iconName: string;
  benefits: string[];
  features: string[];
  benefitsSummary?: string;
}

export interface IPlanCardProps extends ISubscribtioPlan {
  activePlan: string;
}

const iconRecord: Record<string, any> = {
  home: HomeIcon,
  work: WorkIcon,
  lightbulb: LightBulbIcon
};

const getIcon = (iconName: string) => iconRecord[iconName] || iconRecord.home;

function PlanCard({
  name,
  colorScheme,
  highlight,
  iconName,
  features,
  benefits,
  activePlan,
  benefitsSummary
}: IPlanCardProps) {
  return (
    <Box margin={'24px 32px'} style={{ width: '100%' }} border={'all'} width={{ max: '416px' }}>
      <CardHeader
        title={name}
        highlight={highlight}
        iconName={iconName}
        colorScheme={colorScheme}
        isActivePlan={name === activePlan}
      />
      <CardBody features={features} colorScheme={colorScheme} />
      <CardFooter benefits={benefits} benefitsSummary={benefitsSummary} />
    </Box>
  );
}

function CardHeader({
  title,
  highlight,
  iconName,
  colorScheme,
  isActivePlan
}: {
  title: string;
  highlight: HighLight | string;
  iconName: string;
  colorScheme: string;
  isActivePlan: boolean;
}) {
  return (
    <Box border="bottom" pad={'24px'}>
      <CardTitle
        colorScheme={colorScheme}
        highlight={highlight}
        title={title}
        iconName={iconName}
      />
      <Button
        style={{ height: '40px', padding: '0px' }}
        margin="24px 0px"
        {...(isActivePlan ? { secondary: true } : { primary: true })}
        label={isActivePlan ? 'current plan' : 'Select plan'}
      />
    </Box>
  );
}

function CardBody({ features, colorScheme }: { features: string[]; colorScheme: string }) {
  return (
    <Box pad={'24px'}>
      {features.map((feature, i) => (
        <Box align="center" margin={'10px 0px'} direction="row" key={`${feature}_${i}`}>
          <CheckRoundIcon style={{ marginRight: '10px' }} color={colorScheme} />
          <Text size="small">{feature}</Text>
        </Box>
      ))}
    </Box>
  );
}

const CardTitle = ({
  title,
  colorScheme,
  highlight,
  iconName
}: {
  title: string;
  colorScheme: string;
  highlight: HighLight | string;
  iconName: string;
}) => {
  const Icon = getIcon(iconName);
  const highlightIsString = typeof highlight === 'string';
  return (
    <Box align="center" direction="row">
      <Box margin={'0px 16px 0px 0px'} color={colorScheme}>
        <IconButton
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '64px',
            width: '64px'
          }}
          color={colorScheme}
          icon={<Icon />}
        />
      </Box>
      <Box>
        <Heading level={'4'}>{title}</Heading>
        <Text color="#26282E" size="small">
          {highlightIsString ? (
            <Text color="#26282E" size="small" as="span">
              {highlight}
            </Text>
          ) : (
            <>
              <Text color="#26282E" size="small" weight={'bold'} as="span">
                {highlight.main}
              </Text>{' '}
              <Text color="#26282E" size="small" as="span">
                {highlight.sub}
              </Text>
            </>
          )}
        </Text>
      </Box>
    </Box>
  );
};

function CardFooter({
  benefits,
  benefitsSummary
}: {
  benefits: string[];
  benefitsSummary?: string;
}) {
  return (
    <Box border="top" pad={'24px'}>
      <Text weight={'bold'} size="small">
        {benefitsSummary}
      </Text>
      <Box style={{ listStyle: 'inside' }} as="ul">
        {benefits.map((benefit, i) => (
          <Text color="#3B3F49" size="xsmall" margin={'6px 0px'} as="li" key={`${benefit}_${i}`}>
            {benefit}
          </Text>
        ))}
      </Box>
    </Box>
  );
}

export const ActivePlanCard = ({
  highlight,
  iconName,
  name,
  colorScheme,
  features
}: ISubscribtioPlan) => {
  return (
    <Box wrap direction="row" align="center" justify="between">
      <Box>
        <CardTitle
          highlight={highlight}
          iconName={iconName}
          title={name}
          colorScheme={colorScheme}
        />
      </Box>
      <Box wrap align="center" direction="row">
        {features.map((feature, i) => (
          <Box  align="center" margin={'10px 5px'} direction="row" key={`${feature}_${i}`}>
            <CheckRoundIcon style={{ marginRight: '10px' }} color={colorScheme} />
            <Text size="small">{feature}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
PlanCard.propTypes = {};

export default PlanCard;

import React, { SVGProps } from 'react';

const LightBulbIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.9 20 8 20Z" fill="currentColor" />
    <path
      d="M5 17H11C11.55 17 12 16.55 12 16C12 15.45 11.55 15 11 15H5C4.45 15 4 15.45 4 16C4 16.55 4.45 17 5 17Z"
      fill="currentColor"
    />
    <path
      d="M8 0C3.86 0 0.5 3.36 0.5 7.5C0.5 11.32 3.16 13.36 4.27 14H11.73C12.84 13.36 15.5 11.32 15.5 7.5C15.5 3.36 12.14 0 8 0Z"
      fill="currentColor"
    />
  </svg>
);

export default LightBulbIcon;
